@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');


    cdk-virtual-scroll-viewport::-webkit-scrollbar-track{
        border-radius: 10px;
        background-color: #F8F8F8;
    }
    cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-color: mat.get-color-from-palette($primary-palette);
    }
    cdk-virtual-scroll-viewport::-webkit-scrollbar{
        width: 8px;
        height: 8px;
        background-color: #F8F8F8;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: mat.get-color-from-palette($primary-palette);
        border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: mat.get-color-from-palette($primary-palette);
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}