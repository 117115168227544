@import '@angular/material/theming';
@mixin componente-pago-colores($theme) {
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    $color-primario: mat-color($primary);
    $color-acento: mat-color($accent);

    .form-payment label {
        position: absolute;
        height: 20.2px;
        font-size: 13.5px;
        color: $color-primario;
        font-weight: 500;
        top: -10px;
    }
    
    .normal-label {
        display: block;
        position: relative !important;
        top: 0 !important;
    }
    
    .botonMSI.active {
        background-color: $color-primario;
        color: white !important;
    }
    
    .tag-precio {
        margin-top: 14px;
        font-weight: bold;
        font-size: 17px;
        border-bottom: 1px solid #949494;
    }
    
    .swal2-content{
        white-space: pre-line;
    }
    
    .tc-icono {
        font-size: 30px;
        transform: translateY(5px);
        margin-right: 5px;
    }
    
    .input-especial {
        margin-bottom: 14px;
        width: calc( 100% - 40px );
    }
}

    /* Estilos específicos para el mat-select (Fecha Vencimiento) */
    .selection-justification {
        display: grid !important;
    }

    .custom-selection {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }