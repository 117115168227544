@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $color-primario: map.get($color-config, 'primary');
    $color-acento: map.get($color-config, 'accent');
    $color-danger: map.get($color-config, 'warn');

    .exitoSnackbar,
    .errorSnackbar,
    .avisoSnackBar {
        --mdc-snackbar-supporting-text-color: #ffffff !important;
        --mat-mdc-snack-bar-button-color: #ffffff !important;
        --mdc-snackbar-container-color: $color-primario !important;
        z-index: 9999;
        background-color: mat.get-color-from-palette($color-primario) !important;
        margin: 14px !important;
        color: #ffffff !important;
        .mat-simple-snackbar-action {
            color: #ffffff !important;
        }
    }
    .errorSnackbar {
        --mat-mdc-snack-bar-button-color: #ffffff !important;
        --mdc-snackbar-supporting-text-color: #ffffff !important;
        --mdc-snackbar-container-color: $color-danger !important;
        background-color: mat.get-color-from-palette($color-danger) !important;
    }
    .avisoSnackBar {
        --mat-mdc-snack-bar-button-color: #ffffff !important;
        --mdc-snackbar-supporting-text-color: #ffffff !important;
        --mdc-snackbar-container-color: $color-acento !important;
        background-color: mat.get-color-from-palette($color-acento) !important;
        color: #ffffff !important;
        .mat-simple-snackbar-action {
            color: #ffffff !important;
        }
    }
    .tooltipo {
        background: mat.get-color-from-palette($color-primario);
        color: #ffffff;
        // font-weight: bold;
    }
    .tooltip-vehiculo {
        font-size: 14px;
    }
    .tooltip-error {
        background: mat.get-color-from-palette($color-danger);
        color: #ffffff;
    }
    .mat-stroked-button:not([disabled]) {
        border-color: mat.get-color-from-palette($color-primario);
        color: black;
        &:hover {
            background-color: mat.get-color-from-palette($color-primario);
            color: white;
        }
    }
    .check-hint {
        color: mat.get-color-from-palette($color-primario);
    }
    .txt-exito{
        color:#5cb85c !important;
    }
    .txt-exito{
        color:#5cb85c !important;
    }
    .txt-amarillo {
        color: #faa632;
    }
    .txt-brown {
        color: #7d6955;
    }
    .borde-inferior-error {
        border-bottom-color: mat.get-color-from-palette($color-danger) !important;
    }
    .texto-primario {
        color: mat.get-color-from-palette($color-primario);
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}