@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    $sideBarFooter: 102px;
    $sideBarLogo: 64px;
    $sideBarUsuario: 64px;
    $colorTextosPrincipal: #4E5155;
    $colorTextos: #6c757d;
    $colorActivo:  mat.get-color-from-palette($primary-palette);

    .sidenav-container {
        height: 100%;
        overflow: hidden;
    }
    .sidenav {
        width: 250px;
        border-right: none !important;
    }
    .sidenav .mat-toolbar {
        background: inherit;
    }
    .mat-toolbar.mat-primary {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    // Menu sidebar principal
    #sideBarPrincipalLogo {
        height: $sideBarLogo;
        div#imagen {
            height: 100%;
            width: 40px;
            display: contents;
        }
        div#complemento {
            padding: 17px 10px;
            font: 400 14px "Roboto" !important;
            height: 100%;
            width: 159px;
            #lema {
                font: 400 15px "Roboto" !important;
                margin-bottom: 0px;
                color: $colorTextos;
                width: 100%;
            }
            #tipoUsuario {
                font: 400 12px "Roboto" !important;
                margin-bottom: 0px;
                color: $colorActivo;
                width: 100%;
            }
        }
    }
    #sideBarPrincipalUsuario {
        height: 40px !important;
        justify-content: space-between!important;
        text-align: center;
        .usuario {
            font-size: 16px;
        }
        .nombreUsuario {
            font-size: 16px;
        }
        #cambiaCuenta {
            font-size: 12px;
            cursor: pointer;
            padding: 0 16px;
            height: 30px;
            background: $colorActivo !important;
            color: white;
            font-weight: bold;
            border-radius: 3px;
            &:after {
                font-family: "Font Awesome 5 Free";
                content: "\f078";
            }
        }
    }
    #sideBarPrincipalLink {
        height: 40px !important;
        justify-content: space-between!important;
        text-align: center;
        div#link {
            width: 159px;
        }
    }
    #sideBarPrincipalMenuPaginasContenido,
    #sideBarPrincipalMenuConfiguracionContenido {
        .mat-expansion-panel-header-title {
            align-items: center !important;
        }
        .mat-expansion-panel-spacing {
            margin: 0 !important;
        }
        .mat-expansion-panel-header {
            height: 34px !important;
        }
        .mat-expansion-panel-content {
            margin-top: 10px;
        }
        mat-expansion-panel:not([class*='mat-elevation-z']) {
            box-shadow: none !important;
        }
        mat-expansion-panel:not([class*='mat-expanded']) {
            padding: 0;
        }
        .mat-expansion-panel-body {
            padding: 0 24px 8px;
        }
        #iconoModulo {
            color: $colorTextos;
            font-size: 22px;
            width: 24px;
            height: 24px;
            text-align: center;
        }
        #nombreModulo {
            color: $colorTextos;
            font-size: 14px;
            font-weight: 400;
        }
        #nombrePagina {
            color: $colorTextos;
            margin-left: 32px;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .linkRuta {
            color: $colorTextos;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;
            //margin-left: 0px !important;
        }
    }
    #sideBarPrincipalMenuPaginas {
        position: relative;
        height: calc( 100% - 390px) !important;
        overflow-y: auto;
    }
    #sideBarPrincipalMenuConfiguracion {
        height: $sideBarFooter;
        padding: 0 24px;
        #sideBarPrincipalMenuConfiguracionContenido {
            color: $colorTextos;
            display: flex;
            align-items: center!important;
            width: 100%;
            height: 34px;
            cursor: pointer;
            #nombreModulo,
            #iconoModulo {
                color: inherit !important;
                height: 24px;
                font-weight: 400;
            }
            &:hover {
                color: $colorActivo !important;
            }
        }
    }
    .rutaActivaMenu,
    #nombrePagina:hover {
        color: $colorActivo !important;
        font-size: 105% !important;
        span {
            border-bottom: $colorActivo solid 2px;
        }
    }
    .rutaActiva {
        color: $colorActivo !important;
    }
    .linkRuta:hover {
        color: $colorActivo !important;
        font-size: 105% !important;
        span {
            border-bottom: $colorActivo solid 2px;
        }
        text-decoration: none;
    }
    div#contenidoPagina {
        padding: 17.5px;
        background: #F8F8F8;
        color: $colorTextosPrincipal;
        min-height: calc(100vh - 64px);
    }
    #sidenavCuenta {
        width: 300px;
        border-left: none !important;
        .mat-toolbar {
            font-size: 15px;
            color: $colorTextosPrincipal;
            background: white;
        }
        p#cuenta {
            color: $colorTextosPrincipal;
            font-size: 14px;
            border-bottom: #f2f2f2 solid 1px;
            padding: 9px 15px;
            margin-bottom: 0 !important;
            cursor: pointer;
            &:hover {
                background: $colorActivo;
                color: white;
            }
        }
    }
    .cuentaSeleccionada {
        background: $colorActivo;
        color: white !important;
    }
    div#ultimoAcceso {
        color: $colorTextos;
        .etiqueta {
            font-size: 11px;
            margin-bottom: 0 !important;
        }
        .fechaHora {
            font-size: 12px;
            margin-bottom: 0 !important;
        }
    }
    #divBlockUIProgressBar {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 999999;
        #divAreaProgressBar {
            height: 60px;
            width: 300px;
            position: absolute;
            top: calc(50% + 30px);
            left: calc(50% - 150px);
            text-align: center;
            // .mat-progress-bar-fill::after {
            //     background-color: $colorActivo;
            // }
            // .mat-progress-bar-buffer {
            //     background-color: $colorActivo;
            //     opacity: 0.4;
            // }
        }
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}

footer {
    width: 100%;
    bottom: 0px;
    left: 0;
    background-color: #f8f8f8;
    box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #dee2e6;
    z-index: 10;
    &>div {
        display: flex;
        align-items: center;
        min-height: 65px;
    }
    img {
        width: 120px;
        padding: 10px 0px
    }
    a {
        color: #535c68;
        padding: 5px 0px;
        display: block;
        &:hover {
            color: #f9922d;
        }
    }
    @media only screen and (max-width: 768px) {
        position: sticky;
    }
}

.icon-btn {
    background-color: #f6a344;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

// #divSpecial {
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     // left: calc(50% - 150px);
//     // top: calc(50% + 10px);
//     z-index: 999999;
//     // display: flex;
//     /* align-content: center; */
//     // align-items: center;
//     // text-align: center;
// }