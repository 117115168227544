@use '@angular/material' as mat;
@use "./components/index" as components;

@use '../paleta-colores' as palette;
@use '../spinner-mapa';
@use './global';

@use 'src/app/@theme/@components/layout-principal/layout-principal.component.scss';
@use 'src/app/pages/seguros-atlas/@components/layout-public/layout-public.component.scss';
@use 'src/app/pages/@components/pagos/pagos.component.scss';
@use 'src/app/pages/@components/contratante/contratante.component.scss';

@import '../font-roboto';
@import '../font-roboto-serif';

@include mat.core();
@include mat.legacy-core();

// TIPOGRAFÍA
$fuente-familia: "Roboto",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Oxygen",
"Ubuntu",
"Cantarell",
"Fira Sans",
"Droid Sans",
"Helvetica Neue",
sans-serif;

$corporativo-primary: mat.define-palette(palette.$mat-atlas-corporativo-primario);
$corporativo-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$corporativo-warn: mat.define-palette(mat.$red-palette);
$corporativo-theme: mat.define-light-theme(
    (color :(   primary: $corporativo-primary,
                accent: $corporativo-accent,
                warn: $corporativo-warn),
            typography: mat.define-typography-config(),
        ));

@include mat.core-theme($corporativo-theme);
@include mat.all-component-themes($corporativo-theme);
@include mat.all-legacy-component-themes($corporativo-theme);
@include components.custom-theme-into-components($corporativo-theme, mat.define-typography-config());

// Template autos
.autos,
.danos {
    $autos-primary: mat.define-palette(palette.$mat-atlas-autos-primario);
    $autos-accent: mat.define-palette(palette.$mat-atlas-autos-secundario);
    $autos-warn: mat.define-palette(mat.$red-palette);
    $autos-theme: mat.define-light-theme(
        (color :(primary: $autos-primary, accent: $autos-accent, warn: $autos-warn ), typography: mat.define-typography-config()));
    @include mat.core-color($autos-theme);
    @include mat.all-component-colors($autos-theme);
    @include mat.all-legacy-component-colors($autos-theme);
    @include components.custom-theme-into-components($autos-theme, mat.define-typography-config());
}

// Template gastos medicos
.gastos-medicos,
.accidentes-personales {
    $gastos-medicos-primary: mat.define-palette(palette.$mat-atlas-gastosmedicos-primario);
    $gastos-medicos-accent: mat.define-palette(mat.$pink-palette, A400, A100, A700);
    $gastos-medicos-warn: mat.define-palette(mat.$red-palette);
    $gastos-medicos-theme: mat.define-light-theme(
        (color :(primary: $gastos-medicos-primary, accent: $gastos-medicos-accent, warn: $gastos-medicos-warn ), typography: mat.define-typography-config()));
    @include mat.core-color($gastos-medicos-theme);
    @include mat.all-component-colors($gastos-medicos-theme);
    @include mat.all-legacy-component-colors($gastos-medicos-theme);
    @include components.custom-theme-into-components($gastos-medicos-theme, mat.define-typography-config());
}

// Template vida
.vida,
.vida-grupo,
.vida-individual {
    --mdc-protected-button-label-text-color : white !important;
    $vida-primary: mat.define-palette(palette.$mat-atlas-vida-primario);
    $vida-accent: mat.define-palette(mat.$pink-palette, A400, A100, A700);
    $vida-warn: mat.define-palette(mat.$red-palette);
    $vida-theme: mat.define-light-theme((color :(primary: $vida-primary, accent: $vida-accent, warn: $vida-warn ), typography: mat.define-typography-config()));
    @include mat.core-color($vida-theme);
    @include mat.all-component-colors($vida-theme);
    @include mat.all-legacy-component-colors($vida-theme);
    @include components.custom-theme-into-components($vida-theme, mat.define-typography-config());
}

// Template viajes
.viajes {
    $viajes-primary: mat.define-palette(palette.$mat-atlas-viajes-primario);
    $viajes-accent: mat.define-palette(mat.$pink-palette, A400, A100, A700);
    $viajes-warn: mat.define-palette(mat.$red-palette);
    $viajes-theme: mat.define-light-theme((color :(primary: $viajes-primary, accent: $viajes-accent, warn: $viajes-warn ), typography: mat.define-typography-config()));
    @include mat.core-color($viajes-theme);
    @include mat.all-component-colors($viajes-theme);
    @include mat.all-legacy-component-colors($viajes-theme);
    @include components.custom-theme-into-components($viajes-theme, mat.define-typography-config());
}