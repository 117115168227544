@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
	$color-config: mat.get-color-config($theme);
	$color-primario: map.get($color-config, 'primary');
	$color-acento: map.get($color-config, 'accent');
	$color-danger: map.get($color-config, 'warn');

	$paginaActiva : mat.get-color-from-palette($color-primario);
	$paginaNoActiva: #4E5155;
	// Opciones columna estilo
	$fondoOpcionesColumnas: #DEE2E5;
	$colorLetra: #4E5155;
	$filaSeleccionadaFondo: #eff6fd;
	$color-borde-fila: #dee2e6;
	$seleccionado-fg: mat.get-color-from-palette($color-primario, '100-contrast');
	$seleccionado-bg: mat.get-color-from-palette($color-primario, 100);

	generic-table {
		gt-checkbox {
			// FUNCION CHECKBOX
			width: 16px !important;
			height: 16px !important;
			margin: 3px;

			label {
				cursor: pointer !important;
			}

			.custom-checkbox .custom-control-label::before {
				border-radius: .125rem;
			}

			.custom-control-label::before {
				position: initial;
				background-color: #F2F2F2;
				border: #6e6e6e solid 2px;
			}

			.custom-control-label::after {
				position: absolute;
				top: 0.075rem;
				left: -0.5rem;
			}

			.custom-control-input:checked~.custom-control-label::before {
				color: #fff;
				border-color: mat.get-color-from-palette($color-primario);
				background-color: mat.get-color-from-palette($color-primario);
			}
		}

		table {
			width: 100%;
			margin-bottom: 1rem;
			color: $colorLetra !important;
		}

		.table-hover tbody tr:hover {
			color: $seleccionado-fg !important;
			background-color: $seleccionado-bg ;
		}

		.table th,
		.table td {
			vertical-align: middle !important;
			text-align: center;
			padding: .4rem .5rem !important; //nuevo
		}

		// Fila(s) selecionada(s)
		tr.row-selected {
			// FUNCION CHECKBOX
			background: $seleccionado-bg !important;
		}

		// Expandir fila
		tr.row-expanded>td {
			text-align: left;
		}

		// Tabla lineas estilo
		.table thead th {
			border-bottom: 2px solid $color-borde-fila !important;
		}
	}

	.filaAlterna {
		background-color: #F8F8F8;
		vertical-align: middle;
	}

	.filaNormal {
		vertical-align: middle;
	}

	// Fila(s) selecionada(s)
	tr.row-selected {
		background: #f7921e6b !important;
	}

	// Tabla lineas estilo
	.table thead th {
		vertical-align: bottom;
		text-align: center;
		border-bottom: 2px solid $color-borde-fila !important;
	}

	// Paginacion estilo
	li.page-item {
		&.ng-star-inserted {
			a.page-link {
				background: transparent;
				color: $paginaNoActiva;
			}

			&.active {
				background: $paginaActiva;

				a.page-link {
					border-color: $paginaActiva;
					color: white;
					font-weight: 800;
					box-shadow: unset;
				}
			}
		}

		&.disabled {
			a.page-link {
				color: $paginaNoActiva;
				background-color: transparent;
			}
		}

		a.page-link {
			color: $paginaActiva;
			background-color: transparent;
		}
	}

	// Ocultar boton cerrar
	.close:not(:disabled):not(.disabled) {
		display: none;
	}

	.gt-column-settings-panel.ng-star-inserted {
		padding: 1rem !important;
		background: $fondoOpcionesColumnas !important;
	}

	.gt-column-settings-title.ng-star-inserted {
		font-size: 1rem !important;
		font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
		font-weight: 400;
		color: $colorLetra !important;
	}

	.gt-column-settings-help.form-text.text-muted.ng-star-inserted {
		margin-bottom: 5px;
		color: $colorLetra !important;
	}

	.gt-column-settings-item.pr-0.pr-sm-4.ng-star-inserted {
		// display: inline-block !important;
		background: $fondoOpcionesColumnas !important;

		span {
			cursor: grab !important;
			margin-bottom: .3rem;

			&:active {
				cursor: grabbing !important;
			}
		}
	}

	.badgeExito {
		color: #fff;
		background-color: $paginaActiva;
	}

	// Aun no se pero sirven
	div.table-responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
	}

	.side {
		width: 300px;
	}

	th {
		&.sort-enable {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		&.sort-asc {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}

			&::after {
				content: '\f15d' !important;
				display: inline-block;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				border: none !important;
				margin: 4px 0 0 4px !important;
				color: mat.get-color-from-palette($color-primario) !important;
			}

			&.sort-numeric::after {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: '\f162';
				border: none !important;
				vertical-align: baseline !important;
			}

			&.sort-string::after {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: '\f15d';
				border: none !important;
				vertical-align: baseline !important;
			}
		}

		&.sort-desc {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}

			&::after {
				content: '\f881' !important;
				display: inline-block;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				border: none !important;
				margin: 4px 0 0 4px !important;
				color: mat.get-color-from-palette($color-primario) !important;
			}

			&.sort-numeric::after {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: '\f163';
				border: none !important;
				vertical-align: baseline !important;
			}

			&.sort-string::after {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: '\f15e';
				border: none !important;
				vertical-align: baseline !important;
			}
		}
	}

	tr {
		td.clickable>span.gt-row-content {
			cursor: pointer;
			font-weight: bold;
			color: mat.get-color-from-palette($color-primario);
			text-decoration: none;

			&::before {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: '\f0da';
				display: inline-block;
				width: 14px;
				color: black;
			}
		}

		&.row-open {
			td.clickable>span.gt-row-content::before {
				content: '\f0d7';
			}
		}

		&.row-selected {
			background: $filaSeleccionadaFondo;
		}

		&:active {
			background: $filaSeleccionadaFondo !important;
		}
	}

	span.gt-highlight-search {
		background: yellow;
	}

	span.link {
		color: mat.get-color-from-palette($color-primario);
		text-decoration: none;
		cursor: pointer;

		&:focus,
		&:hover {
			color: mat.get-color-from-palette($color-primario);
			text-decoration: underline;
		}
	}

	.temp::after {
		content: '';
		display: block;
		width: 100%;
		height: calc(100% - 33px);
		background: #fff;
		position: absolute;
		top: 33px;
		opacity: 0.5;
		z-index: 0;
	}

	@media screen and (max-width: 768px) {
		generic-table {
			table {
				thead tr {
					display: none !important;
				}

				tbody {
					border-top: mat.get-color-from-palette($color-primario) 5px solid !important;
				}
			}
		}

		generic-table table.table-stacked tr+tr {
			border-top: 3px solid mat.get-color-from-palette($color-primario) !important;
		}
	}

	@media (max-width: 768px) {
		generic-table table.table-stacked tr td {
			display: flex !important; //block
			border: none;
		}

		.gt-row-content {
			width: 100% !important;
			text-align: right !important;
		}
	}

	.botonTabla,
	.botonTablaOutline {
		display: block;
		color: mat.get-color-from-palette($color-primario, '500-contrast') !important;
		border: mat.get-color-from-palette($color-primario) 1px solid;
		background: mat.get-color-from-palette($color-primario);
		border-radius: 5px;
		padding: 5px;
		font-size: 12px;
		text-align: center;
		cursor: pointer;

		@media (max-width: 768px) {
			margin-left: 50px;
		}
	}

	.botonTablaOutline {
		color: mat.get-color-from-palette($color-primario) !important;
		border: mat.get-color-from-palette($color-primario) 1px solid;
		background: transparent;
		font-weight: bold;

		&:hover {
			background: mat.get-color-from-palette($color-primario) !important;
			color:mat.get-color-from-palette($color-primario, '500-contrast') !important;
		}
	}

	.table th,
	.table td {
		vertical-align: middle !important;
		text-align: center;
		padding: .5rem;
	}

	gt-table-info {
		span {
			color: $colorLetra !important;
		}
	}

	.min-col-width-120 {
		min-width: 120px;
	}

	@media (min-width: 768px) {
		.col-width-95 {
			width: 90px;
		}

		.col-width-60 {
			width: 60px;
		}

		.col-width-70 {
			width: 70px;
		}
	}

	.icono-celda {
		font-size: 16px;
		width: 16px;
		height: 16px;
	}

}

@mixin typography($theme) {
	$typography-config: mat.get-typography-config($theme);
	$my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
	$color-config: mat.get-color-config($theme);

	@if $color-config !=null {
		@include color($theme);
	}

	@if $typography-config !=null {
		@include typography($typography-config);
	}
}