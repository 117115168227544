@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    $color-primario: mat.get-color-from-palette($primary-palette);
    $color-acento: mat.get-color-from-palette($accent-palette);

    .botonEnviar {
        background-color: $color-primario;
    }

    .valorTexto {
        font-weight: bold !important;
        font-size: 14px;
        overflow-wrap: break-word;
    }

    .valorLabel {
        font-size: 12px;
        // color: #9F9FB3
        color: $color-acento
    }

    .cardBorder {
        border-radius: 10px;
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}