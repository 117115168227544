@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    $sideBarFooter: 102px;
    $sideBarLogo: 64px;
    $sideBarUsuario: 64px;
    $colorTextosPrincipal: #4e5155;
    $colorTextos: #6c757d;
    $colorTituloPrincipal: #ffffff;
    $colorActivo:  mat.get-color-from-palette($primary-palette);

    .sidenav-container {
        height: 100%;
        overflow: hidden;
    }

    .sidenav-content {
        width: 250px;
        border-right: none !important;
        background: white;
    }

    // Toolbar
    #toolbarPrincipal {
        height: $sideBarLogo;
        cursor: pointer;
        // color: #4e5155 !important;

        div#toolbarImagen {
            height: 100%;
            width: 40px;
            display: contents;
        }

        div#toolbarTitulos {
            padding: 17px 10px;
            font: 400 13px "Roboto" !important;
            height: 100%;
            width: 159px;

            #toolbarLema {
                font: 400 15px "Roboto" !important;
                margin-bottom: 0px;
                color: $colorTituloPrincipal;
                width: 100%;
            }
        }

        .toolbarLogo {
            margin: auto;
            height: 65px !important;
        }

        .toolbarLinks {
            padding: 0 10px !important;
        }

    }

    #sideBarContentPublic {
        color: $colorTextosPrincipal !important;
        background-color: white !important;
    }

    // Menu sidebar principal
    #sidePrincipalLogo {
        height: $sideBarLogo;

        div#sideImagen {
            height: 100%;
            width: 40px;
            display: contents;
        }

        div#sideTitulos {
            padding: 17px 10px;
            font: 400 14px "Roboto" !important;
            height: 100%;
            width: 159px;

            #sideLema {
                font: 400 15px "Roboto" !important;
                margin-bottom: 0px;
                color: $colorTextos;
                width: 100%;
            }
        }
    }

    /*Contenido del sideBar*/
    #sidePrincipalMenuPaginasContenido,
    #sidePrincipalMenuConfiguracionContenido,
    #sidePrincipalContentMenuPaginas,
    #sidePrincipalContentMenuPaginasContenido {
        .mat-expansion-panel-header-title {
            align-items: center !important;
        }

        .mat-expansion-panel-spacing {
            margin: 0 !important;
        }

        .mat-expansion-panel-header {
            height: 34px !important;
        }

        .mat-expansion-panel-content {
            margin-top: 10px;
        }

        mat-expansion-panel:not([class*="mat-elevation-z"]) {
            box-shadow: none !important;
        }

        mat-expansion-panel:not([class*="mat-expanded"]) {
            padding: 0;
        }

        .mat-expansion-panel-body {
            padding: 0;
        }

        #sideIconoModulo {
            color: $colorTextos;
            font-size: 24px;
            width: 24px;
            height: 24px;
            text-align: center;
        }

        #sideNombreModulo {
            color: $colorTextos;
            font-size: 14px;
        }

        #sideNombrePagina {
            color: $colorTextos;
            margin-left: 32px;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;
            overflow-wrap: break-word;
        }

        .linkRuta {
            color: $colorTextos;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;
            //margin-left: 0px !important;
        }
    }

    #sidePrincipalMenuPaginasContenido,
    #sidePrincipalContentMenuPaginas {
        margin-top: 10px;
    }

    /*Contenedor sidebar*/
    #sidePrincipalMenuPaginas {
        background: white;
        margin-top: 10px;
        position: relative;
        height: calc( 100% - 350px) !important;
        // height: 100% !important;
        overflow-y: auto;
    }

    /*Menu inferior*/
    #sidePrincipalMenuConfiguracion {
        height: $sideBarFooter;
        padding: 0 24px;

        #sidePrincipalMenuConfiguracionContenido {
            color: $colorTextos;
            display: flex;
            align-items: center !important;
            width: 100%;
            height: 34px;
            cursor: pointer;

            #sideNombreModulo,
            #sideIconoModulo {
                color: inherit !important;
                height: 24px;
            }

            &:hover {
                color: $colorActivo !important;
            }
        }
    }
    /*Ruta Activa en el menú con children nodes*/
    .rutaActivaMenu,
    #sideNombrePagina:hover {
        color: $colorActivo !important;
        font-size: 105% !important;

        span {
            border-bottom: $colorActivo solid 2px;
        }
    }

    .rutaActivaToolbar {
        background-color: rgba(0,0,0,.2);
        margin: 10px !important;
    }

    // .rutaActivaToolbar,
    // #sideNombreModulo:hover {
    //     border-bottom: #ffffff solid 1px;
    // }

    .rutaActiva {
        color: $colorActivo !important;
    }

    .linkRuta:hover {
        color: $colorActivo !important;
        font-size: 105% !important;

        span {
            border-bottom: $colorActivo solid 2px;
        }

        text-decoration: none;
    }
    /*Contenido principal del sidenav content */ 
    #contenidoPaginaPublica {
        padding: 17.5px !important;
        background: #f8f8f8;//#f8f8f8;
        color: $colorTextosPrincipal !important;
        min-height: calc(100vh - 194px) !important;
        width: 100%;
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}

footer {
    width: 100%;
    bottom: 0px;
    left: 0;
    background-color: #f8f8f8;
    box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.08);
    border-top: 1px solid #dee2e6;
    z-index: 10;

    &>div {
        display: flex;
        align-items: center;
        min-height: 65px;
    }

    img {
        width: 120px;
        padding: 10px 0px;
    }

    a {
        color: #535c68;
        padding: 5px 0px;
        display: block;

        &:hover {
            color: #f9922d;
        }
    }

    @media only screen and (max-width: 768px) {
        position: sticky;
    }
}

.icon-btn {
    background-color: #f6a344;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.icono-menu {
    visibility :hidden;
}

@media only screen and (max-width: 1054px) {
    .icono-menu {
        visibility :visible;
    }
}
