@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .highlightTableColor {
        background-color: #ffffff !important;
        .mat-cell {
            color: white;
        }
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}