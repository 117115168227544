/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Estilo global para FOCUS
:focus {
    outline: none !important;
}

// Card
$color-borde-card: #F2F2F2;

.mat-mdc-card {
    padding: 0px !important;

    .mat-mdc-card-title,
    .mat-mdc-card-subtitle {
        color: rgb(172, 170, 170) !important;
        font-weight: 600;
        font-size: 16px !important;
        border-top: 1px $color-borde-card solid;
        padding: 17.5px 17.5px 5px 17.5px !important;
        margin: 0 !important;
    }

    .mat-mdc-card-subtitle {
        font-size: 14px !important;
        font-weight: 400;
        padding: 17.5px 17.5px 0 25px !important;
    }

    .mat-mdc-card-content,
    .mat-mdc-card-actions {
        padding: 17.5px !important;
        margin: 0 !important;
    }

    .mat-mdc-card-footer {
        border-top: 1px $color-borde-card solid;
        padding: 17.5px !important;
        margin: 0 !important;
    }
}



.mat-card-especial {
    background: transparent !important;
    box-shadow: unset !important;
    border: 1px solid #dee2e6;
}

// Color de texto de los buttons accent
.mat-mdc-raised-button.mat-primary:not(:disabled) {
    color: white !important;
}

// Inputs background colors
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid #dadada !important;
}

.inputFormulario {
    width: 100% !important;
    margin-bottom: 14px;
    // height: 40px !important;
    background: none;
}

h4#titulo {
    font-weight: 200;
}

.alturaCalendario {
    overflow-x: hidden !important;
    height: 352px;
    max-width: unset !important;

    @media (max-width: 768px) {
        max-width: 80% !important;
    }
}

.mat-button-sm {
    padding: 0.188rem 0.6875rem !important;
    font-size: 0.75rem;
    line-height: 1.5 !important;
    border-radius: 0.25rem !important;
    min-width: 1% !important;
}

.red-snackbar {
    background: #F44336;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    min-width: 450px !important;
    max-width: 800px !important;
}

.negritas {
    font-weight: bold;
}

// Colores tarjetas
.gastosMedicosBgFg {
    background-color: #508cbe !important;
    color: white !important;
}

.vidaBgFg {
    background-color: #64b95e !important;
    color: white !important;
}

.autosBgFg {
    background-color: #f7931e !important;
    color: white !important;
}

.viajesBgFg {
    background-color: #349e8f !important;
    color: white !important;
}

.pymeBgFg {
    background-color: #f7931e !important;
    color: white !important;
}

// Colores tarjetas
.gastosMedicosTexto {
    color: #508cbe !important;
}

.vidaTexto {
    color: #64b95e !important;
}

.autosTexto {
    color: #f7931e !important;
}

.viajesTexto {
    color: #349e8f !important;
}

.pymeTexto {
    color: #f7931e !important;
}

.mat-mdc-list-icon {
    .mat-button-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
}

.menu-tarjeta {
    margin-top: 9px;
    background-color: white !important;

    .mat-menu-content {
        padding: 2px 0 !important;
    }

    .mat-menu-item {
        color: #6c757d !important;
        height: 36px;
    }
}

//Stepper
.mat-stepper-horizontal,
.mat-stepper-vertical {
    background-color: transparent;
}

// Texto ayuda
.texto-ayuda {
    margin-top: -21px;

    span {
        font-size: 13px;
        font-weight: 500;
        color: #64b95e;
    }
}

::ng-deep .pac-container {
    // z-index: 1000000;
    background-color: #FFF;
    z-index: 10000000000000000 !important;
    position: fixed !important;
    display: block;
    float: left;
}

//Impresion Estados De Cuenta
@media print {
    html body {
        margin: 0 !important;
    }

    mat-sidenav.mat-drawer.mat-sidenav {
        display: none !important;
    }

    mat-sidenav-content.mat-drawer-content.mat-sidenav-content {
        margin-left: 10px !important;
    }
}

//Botones Outline 
.gastos-medicos .mat-mdc-outlined-button,
.accidentes-personales .mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: #508cbe;
}

.vida-individual .mat-mdc-outlined-button,
.vida-grupo .mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: #64b95e;
}


.pago-linea-component {
}