@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    $color-primario: mat.get-color-from-palette($primary-palette);
    $color-acento: mat.get-color-from-palette($accent-palette);

    .form-payment label {
        position: absolute;
        height: 20.2px;
        font-size: 13.5px;
        color: $color-primario;
        font-weight: 500;
        top: -10px;
    }
    
    .normal-label {
        display: block;
        position: relative !important;
        top: 0 !important;
    }
    
    .botonMSI.active {
        background-color: $color-primario;
        color: white !important;
    }
    
    .tag-precio {
        margin-top: 14px;
        font-weight: bold;
        font-size: 17px;
        border-bottom: 1px solid #949494;
    }
    
    .swal2-content{
        white-space: pre-line;
    }
    
    .tc-icono {
        font-size: 30px;
        transform: translateY(5px);
        margin-right: 5px;
    }
    
    .input-especial {
        margin-bottom: 14px;
        width: calc( 100% - 40px );
    }

    .mat-mdc-checkbox label {
        position: relative;
    }
}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}