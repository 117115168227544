@use 'SASS:map';
@use '@angular/material' as mat;


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .mdc-dialog__container, .mat-mdc-dialog-container {
        background-color: #F2F2F2 !important;
        border-radius: 14px !important;
        padding: 0 !important;
        max-height: 90vh !important;
        max-width: initial !important;
        .mdc-dialog__title {
            background-color: mat.get-color-from-palette($primary-palette);
            color: #ffffff !important;
            // color: mat.get-color-from-palette($primary-palette, '500-contrast');
            text-align: center;
            padding: 10px 0px;
            position: relative;
            margin: 0 !important;
            font-size: 20px !important;
            display: flex;
            max-height: 52px;
            width: 100%;
            .icon-close {
                cursor: pointer;
            }
        }
        .mat-dialog-title-brown {
            background-color: #f4f3f2;
            color: #b0a498;
            padding: 10px 0px;
            position: relative;
            margin: 0 !important;
            font-size: 20px !important;
            width: 100%;
        }

        .mdc-dialog__content.mat-dialog-content-sucess-alert {
            border-radius: 4px !important;
            background-color: #5cb85c !important;
            color: white;
            padding: 10px 0px;
            max-height: 90vh !important;
            min-width: 45vh;
            max-width: 90vh;
        }

        .mdc-dialog__content.mat-dialog-content-info-alert {
            border-radius: 4px !important;
            background-color: #f4f3f2 !important;
            padding: 10px 0px;
            color: #7d6955;
            max-height: 90vh !important;
            min-width: 45vh;
            max-width: 90vh;
        }

        .mdc-dialog__content.mat-dialog-content-error-alert {
            border-radius: 4px !important;
            background-color: #ef3c46 !important;
            padding: 10px 0px;
            color: white;
            max-height: 90vh !important;
            min-width: 45vh;
            max-width: 90vh;
        }
        .mdc-dialog__content {
            padding: 17.5px !important;
            margin: 0 !important;
            max-height: calc(90vh - 113px) !important;
            // max-height: unset !important;
        }
        .mat-dialog-content-brown {
            padding: 15.5px !important;
            background-color: #f4f3f2;
        }
        .mat-mdc-dialog-actions {
            padding: 0 17.5px 12.5px 17.5px !important;
            margin: 0 !important;
            max-height: 61px;
        }
    }

    .mdc-dialog__container.mat-dialog-content-brown, .mat-mdc-dialog-container.mat-dialog-content-brown {
        border-radius: 4px !important;
        background-color: #b0a498 !important;
        padding: 0 !important;
        max-height: 90vh !important;
    }

    .mat-mdc-dialog-container > .mat-dialog-content-sucess-alert, 
    .mat-mdc-dialog-container > .mat-dialog-content-info-alert, 
    .mat-mdc-dialog-container > .mat-dialog-content-error-alert {
        border-radius: 5px;
    }

}

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);
    $my-font-family: mat.font-family($typography-config);

}


@mixin theme($theme, $typography-config) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}